export const planUpgradePathConstants = {
  ID: 'id',

  UPGRADE_TO: 'upgrade_to_plan_id',
  UPGRADE_TO_LABEL: 'Upgrade To',

  UPGRADE_FROM: 'upgrade_from_plan_id',
  UPGRADE_FROM_LABEL: 'Upgrade From',

  LOW_RECURRING_MIN_DISCOUNT: 'lower_recurring_min_discount',
  LOW_RECURRING_MIN_DISCOUNT_LABEL: 'Lower Recurring',

  HIGH_RECURRING_MIN_DISCOUNT: 'higher_recurring_min_discount',
  HIGH_RECURRING_MIN_DISCOUNT_LABEL: 'Higher Recurring',

  LOW_MONTHLY_MIN_DISCOUNT: 'lower_monthly_min_discount',
  LOW_MONTHLY_MIN_DISCOUNT_LABEL: 'Lower Monthly',

  HIGH_MONTHLY_MIN_DISCOUNT: 'higher_monthly_min_discount',
  HIGH_MONTHLY_MIN_DISCOUNT_LABEL: 'Higher Monthly',

  SHOULD_COMPARE_TO_PLAN_MIN_PRICE: 'should_compare_to_plan_min_price',
  SHOULD_COMPARE_TO_PLAN_MIN_PRICE_LABEL: 'Use Floor Price',
};

export const defaultSelectOption = { label: 'Select an option', value: '' };
