import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { i9ImmigrationStatusRadioConfig } from '@/lib';
import { onboardingConstants } from '@/lib/constants';
import { CustomFormElement, DateOfBirth, PostalCode, Loader } from '@/components/common';
import { addFsExcludeClass } from '@/lib/utils';
import { defaultSelectOption } from '@/lib/configs';
import { selectStates } from '@/redux/addresses';
import { requestDocumentViewLinkAsync, requestI9SignLinkAsync } from '@/redux/contracts';
import { repProfileSelector, hrDataSelector } from '@/redux/onboarding';
import { Button } from '@/components';
import { CustomErrorMessage } from '@/components/common/Form';
import { EMAIL_REGEX } from '@/lib/validations';
import { documentViewLinkLoadingSelector } from '@/redux/loading';

const {
  I9_FIRST_NAME,
  FIRST_NAME_LABEL,
  I9_LAST_NAME,
  LAST_NAME_LABEL,
  I9_MIDDLE_INITIAL,
  MIDDLE_INITIAL_LABEL,
  I9_STREET_ADDRESS,
  I9_STREET_ADDRESS_LABEL,
  I9_CITY,
  CITY_LABEL,
  CITY_PLACEHOLDER,
  I9_STATE,
  STATE_LABEL,
  I9_ZIP,
  ZIP_LABEL,
  ZIP_PLACEHOLDER,
  I9_BIRTHDATE,
  DATE_OF_BIRTH_LABEL,
  I9_SSN,
  I9_SSN_LABEL,
  SSN_PLACEHOLDER,
  I9_EMAIL,
  I9_EMAIL_LABEL,
  I9_EMAIL_PLACEHOLDER,
  I9_IMMIGRATION_STATUS,
  I9_IMMIGRATION_STATUS_LABEL,
  FEDERAL_DISCLAIMER,
  I9_CLICKED,
  I9_COMPLETED_NAME,
  DOCUMENT_TYPE_I9,
} = onboardingConstants;

const W9Information = ({
  canEditField,
  onChangeHandler,
  onBlurTrimSpace,
  states,
  rep,
  hrData,
  userId,
  viewLoading,
}) => {
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const dispatch = useDispatch();

  const isCompleted = useMemo(() => {
    return hrData.i9Completed;
  }, [hrData]);

  const isSubmitted = useMemo(() => {
    return hrData.i9Submitted !== null;
  }, [hrData]);

  const previousI9 = useMemo(() => {
    return hrData.previousI9;
  }, [hrData]);

  const addressStates = useMemo(() => {
    const countryStates = states?.[rep.addressCountry] ?? [];

    return [...defaultSelectOption, ...countryStates];
  }, [states, rep]);

  const [showForm, setShowForm] = useState(!isCompleted && !isSubmitted && !previousI9);
  const [signLoading, setSignLoading] = useState(false);
  const [signDisabled, setSignDisabled] = useState(true);

  const ssnMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  const onViewLinkLoaded = ({ link }) => {
    window.open(link, '_blank')?.focus();
  };

  const onViewClick = () => {
    dispatch(requestDocumentViewLinkAsync.request({
      documentType: DOCUMENT_TYPE_I9,
      userId: userId,
      callback: onViewLinkLoaded,
    }));
  };

  const onSignClick = () => {
    const body = {
      first_name: getValues(I9_FIRST_NAME),
      last_name: getValues(I9_LAST_NAME),
      middle_name: getValues(I9_MIDDLE_INITIAL),
      address: getValues(I9_STREET_ADDRESS),
      city: getValues(I9_CITY),
      state: getValues(I9_STATE),
      zip: getValues(I9_ZIP),
      email: getValues(I9_EMAIL),
      ssn: getValues(I9_SSN)?.replace(/\D/g, ''),
      dob: getValues(I9_BIRTHDATE),
      immigration_status: getValues(I9_IMMIGRATION_STATUS),
    };

    setSignLoading(true);
    dispatch(requestI9SignLinkAsync.request({
      ...body,
      callback: onSignLinkLoaded,
      errorCallback: () => setSignLoading(false)
    }));
  };

  const onSignLinkLoaded = ({ link }) => {
    setValue(I9_CLICKED, true, { shouldValidate: true });
    setValue(I9_COMPLETED_NAME, false, { shouldValidate: true });
    window.open(link, '_blank')?.focus();
    setSignLoading(false);
    setShowForm(false);
  };

  const data = getValues([
    I9_FIRST_NAME,
    I9_MIDDLE_INITIAL,
    I9_LAST_NAME,
    I9_STREET_ADDRESS,
    I9_CITY,
    I9_STATE,
    I9_IMMIGRATION_STATUS,
  ]);

  useEffect(() => {
    const isFilled = Object?.values(data).every((item) => item?.length > 0);
    const email = getValues(I9_EMAIL);
    const ssn = getValues(I9_SSN);
    const zip = getValues(I9_ZIP);

    const isEmailValid = EMAIL_REGEX.test(email);
    const isZipValid = zip?.length >= 5;
    const isSsnValid = !ssn || ssn?.length === 11;

    setSignDisabled(!isFilled || !isEmailValid || !isZipValid || !isSsnValid);
  }, [data, getValues]);

  return <>
    {!showForm ?
      (
        <div className="grid grid-cols-1 mt-2 gap-y-6 gap-x-4 sm:grid-cols-6 justify-center">
          {viewLoading ?
            <Loader className="col-span-6" />
            : (
              <div className="grid grid-cols-2 col-span-6 gap-2">
                <Button
                  className="col-span-1"
                  onClick={() => onViewClick()}
                  disabled={!hrData.i9Completed}
                >
                  View I9
                </Button>
                <Button
                  className="col-span-1"
                  onClick={() => setShowForm(true)}
                  disabled={false}
                >
                  Replace Current I9
                </Button>
              </div>
            )}
        </div>
      )
      :
      (
        <div className="grid grid-cols-1 mt-2 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className={'col-span-6 text-xl'}>Basic Information</div>
          <CustomFormElement
            colSpan={2}
            id={I9_FIRST_NAME}
            name={I9_FIRST_NAME}
            label={FIRST_NAME_LABEL}
            type="text"
            onChange={onChangeHandler}
            onBlur={(event) => onBlurTrimSpace(event, I9_FIRST_NAME)}
            register={register}
            error={errors?.i9FirstName}
            required
            className={addFsExcludeClass()}
            disabled={!canEditField(I9_FIRST_NAME)}
          />

          <CustomFormElement
            colSpan={2}
            id={I9_LAST_NAME}
            name={I9_LAST_NAME}
            label={LAST_NAME_LABEL}
            type="text"
            onChange={onChangeHandler}
            onBlur={(event) => onBlurTrimSpace(event, I9_LAST_NAME)}
            register={register}
            error={errors?.i9LastName}
            required
            className={addFsExcludeClass()}
            disabled={!canEditField(I9_LAST_NAME)}
          />

          <CustomFormElement
            colSpan={2}
            id={I9_MIDDLE_INITIAL}
            name={I9_MIDDLE_INITIAL}
            label={MIDDLE_INITIAL_LABEL}
            type="text"
            onChange={onChangeHandler}
            onBlur={(event) => onBlurTrimSpace(event, I9_MIDDLE_INITIAL)}
            register={register}
            error={errors?.i9MiddleInitial}
            required
            className={addFsExcludeClass()}
            disabled={!canEditField(I9_MIDDLE_INITIAL)}
          />

          <CustomFormElement
            colSpan={6}
            id={I9_STREET_ADDRESS}
            name={I9_STREET_ADDRESS}
            label={I9_STREET_ADDRESS_LABEL}
            type="text"
            onChange={onChangeHandler}
            onBlur={(event) => onBlurTrimSpace(event, I9_STREET_ADDRESS)}
            register={register}
            error={errors?.i9Address}
            required
            className={addFsExcludeClass()}
            disabled={!canEditField(I9_STREET_ADDRESS)}
          />

          <CustomFormElement
            colSpan={2}
            id={I9_CITY}
            name={I9_CITY}
            label={CITY_LABEL}
            type="text"
            onChange={onChangeHandler}
            onBlur={(event) => onBlurTrimSpace(event, I9_CITY)}
            register={register}
            error={errors?.i9City}
            required
            disabled={!canEditField(I9_CITY)}
            placeholder={CITY_PLACEHOLDER}
          />

          <CustomFormElement
            colSpan={2}
            id={I9_STATE}
            name={I9_STATE}
            label={STATE_LABEL}
            type="select"
            selectOptions={addressStates}
            onChange={onChangeHandler}
            register={register}
            error={errors?.i9State}
            required
            disabled={!canEditField(I9_STATE)}
          />

          <PostalCode
            colSpan={2}
            id={I9_ZIP}
            name={I9_ZIP}
            label={ZIP_LABEL}
            onChange={onChangeHandler}
            register={register}
            error={errors?.i9zip}
            required
            className={addFsExcludeClass()}
            disabled={!canEditField(I9_ZIP)}
            placeholder={ZIP_PLACEHOLDER}
          />

          <DateOfBirth
            colSpan={3}
            label={DATE_OF_BIRTH_LABEL}
            name={I9_BIRTHDATE}
            id={I9_BIRTHDATE}
            required
            onChange={onChangeHandler}
            type="date"
            showYearDropdown
            error={errors?.i9Dob}
            register={register}
          />

          <CustomFormElement
            colSpan={3}
            id={I9_SSN}
            name={I9_SSN}
            label={I9_SSN_LABEL}
            type="masked"
            mask={ssnMask}
            onChange={onChangeHandler}
            register={register}
            error={errors?.w9accountNumbers}
            className={addFsExcludeClass()}
            disabled={!canEditField(I9_SSN)}
            placeholder={SSN_PLACEHOLDER}
          />

          <CustomFormElement
            colSpan={3}
            name={I9_EMAIL}
            id={I9_EMAIL}
            label={I9_EMAIL_LABEL}
            onChange={onChangeHandler}
            required
            type="text"
            error={errors?.i9Email}
            register={register}
            className={addFsExcludeClass()}
            placeholder={I9_EMAIL_PLACEHOLDER}
          />

          <div className={'col-span-6 text-xl border-t'} />
          <div className={'col-span-6 text-sm font-bold'}>{FEDERAL_DISCLAIMER}</div>

          <CustomFormElement
            colSpan={6}
            outerClassName=""
            innerClassName="grid grid-cols-1 !space-x-0 !px-0 !py-0"
            panelWrapperClassName="p-4 text-sm border-b"
            type="radio"
            id={I9_IMMIGRATION_STATUS}
            name={I9_IMMIGRATION_STATUS}
            label={I9_IMMIGRATION_STATUS_LABEL}
            register={register}
            radioOptions={i9ImmigrationStatusRadioConfig}
            onChange={onChangeHandler}
            required
            orientation="horizontal"
            disabled={!canEditField(I9_IMMIGRATION_STATUS)}
          />

          {signLoading ?
            <Loader className="col-span-6" />
            :
            (
              <div className={'grid grid-cols-2 col-span-6 gap-4'}>
                {(isCompleted || isSubmitted || previousI9) && (
                  <Button
                    className="col-span-1"
                    onClick={() => setShowForm(false)}
                    disabled={false}
                  >
                    Cancel
                  </Button>
                )}

                <Button
                  className="col-span-1"
                  onClick={() => onSignClick()}
                  disabled={signDisabled}
                >
                  Fill & Sign
                </Button>
              </div>
            )
          }
        </div>
      )
    }
    {errors?.i9Clicked?.message && (
      <CustomErrorMessage className="w-full" text={errors?.i9Clicked?.message} />
    )}
  </>
};

W9Information.propTypes = {
  userId: PropTypes.number,
  isAdmin: PropTypes.bool,
  wizardType: PropTypes.string,
  canEditField: PropTypes.func,
  experiences: PropTypes.array,
  onChangeHandler: PropTypes.func,
  onBlurTrimSpace: PropTypes.func,
  pictureIsLoading: PropTypes.bool,
  profilePictureFile: PropTypes.shape({
    fileName: PropTypes.string,
    file: PropTypes.string,
  }),
  uploadProfilePicture: PropTypes.func,
  isSsnLoading: PropTypes.bool.isRequired,
  states: PropTypes.object,
  rep: PropTypes.object,
  hrData: PropTypes.object,
  viewLoading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  rep: repProfileSelector(state),
  hrData: hrDataSelector(state),
  states: selectStates(state),
  viewLoading: documentViewLinkLoadingSelector(state),
});

export default connect(mapStateToProps)(W9Information);
